import { graphql, useStaticQuery } from "gatsby"

export const FetchAllCategories = () => {
    const data = useStaticQuery(graphql`
    query { 
        allWpProductCategory {
            nodes {
                id
                count
                name
                slug
            }
        }
    }
`)

return data.allWpProductCategory.nodes
}