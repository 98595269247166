import { graphql, useStaticQuery } from "gatsby"

// PRODUCTS
export const fetchAllProducts = () => {
  const data = useStaticQuery(graphql`
   query {
    allWpProduct {
        totalCount
        nodes {
            name
            id
            slug
            databaseId
            image {
                sourceUrl
            }
                productTags {
                    nodes {
                    name
                    }
                }
                productCategories {
                    cat: nodes {
                    name
                    id
                    slug
                }
            }
       
        ... on WpSimpleProduct {
            id
            name
            price
            }
        }
    }
}
`)

  return data.allWpProduct.nodes
}

